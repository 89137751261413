import { graphql } from "gatsby";
import React from "react";
import Layout from "../../components/Layout";
import Works from "../../components/Works";

function WorksPage({ location, data }) {
  const images = data.images.nodes.reduce((result, node) => {
    return {
      ...result,
      [node.slug]: node.mainImage,
    };
  }, {});

  const previews = data.previews.nodes.reduce((result, node) => {
    return {
      ...result,
      [node.slug]: node.preview,
    };
  }, {});

  const previewsImages = data.previewsImages.nodes.reduce((result, node) => {
    return {
      ...result,
      [node.slug]: node.previewImage,
    };
  }, {});

  const works = data.works.nodes.map((work) => ({
    ...work,
    mainImage: images[work.slug],
    preview: previews[work.slug],
    previewImage: previewsImages[work.slug],
  }));

  return (
    <Layout
      location={location}
      languages={data.site.siteMetadata.languages}
      headerProps={{
        className: "bg-transparent",
      }}
    >
      <Works works={works} />
    </Layout>
  );
}

export default WorksPage;

export const pageQuery = graphql`
  query WorksEsQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    images: allDatoCmsWork(filter: { locale: { eq: "en" } }) {
      nodes {
        slug
        mainImage {
          gatsbyImageData(layout: FULL_WIDTH, width: 400, placeholder: BLURRED)
        }
      }
    }
    previews: allDatoCmsWork(filter: { locale: { eq: "en" } }) {
      nodes {
        slug
        preview {
          url
        }
      }
    }
    previewsImages: allDatoCmsWork(filter: { locale: { eq: "en" } }) {
      nodes {
        slug
        previewImage {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          alt
        }
      }
    }
    works: allDatoCmsWork(
      filter: { locale: { eq: "es" } }
      sort: { fields: meta___createdAt, order: DESC }
    ) {
      nodes {
        slug
        title
        brand
        challenge {
          value
        }
      }
    }
  }
`;
